/*!
Copyright (C) 2020 Liberty Infrasystems. All rights reserved.
*/
/* eslint-disable no-console, class-methods-use-this, max-classes-per-file */

const ajax = require('axios');

async function getJson(path, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.get(path, {
        headers: {
            Accept: 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

async function postJsonAcceptJson(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request ? JSON.stringify(request) : undefined, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

class CurrentAccount {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async get(request) {
        return getJson(`${this.url}`, request, { requestHeaders: this.requestHeaders });
    }

    async delete() {
        return postJsonAcceptJson(`${this.url}/delete/account`, null, null, { requestHeaders: this.requestHeaders });
    }

    async check(request) {
        return postJsonAcceptJson(`${this.url}/check/account`, request, null, { requestHeaders: this.requestHeaders });
    }
}

/**
 * This is a resource class to manage authorized client programs
 */
class Client {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/client`, request, null, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        return postJsonAcceptJson(`${this.url}/delete/client`, null, query, { requestHeaders: this.requestHeaders });
    }

    /**
     * Search available client tokens assigned to this account
     */
    async search(query) {
        return getJson(`${this.url}/search/client`, query, { requestHeaders: this.requestHeaders });
    }
}

/**
 * This is a resource class to manage authorization tokens for client software
 * using the server's Client API.
 */
class ClientToken {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/client-token`, request, null, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        return postJsonAcceptJson(`${this.url}/delete/client-token`, null, query, { requestHeaders: this.requestHeaders });
    }

    /**
     * Search available client tokens assigned to this account
     */
    async search(query) {
        return getJson(`${this.url}/search/client-token`, query, { requestHeaders: this.requestHeaders });
    }
}

class Domain {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/domain`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/domain`, query, { requestHeaders: this.requestHeaders }); // TODO:  should be ${this.url}/state/domain (need to change server first)
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/domain`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/domain`, null, query, { requestHeaders: this.requestHeaders });
    }

    async list(query) {
        return getJson(`${this.url}/search/domain`, query, { requestHeaders: this.requestHeaders });
    }

    async checkWhois(query) {
        return postJsonAcceptJson(`${this.url}/check/domain-whois`, null, query, { requestHeaders: this.requestHeaders });
    }

    async checkNameservers(query) {
        return postJsonAcceptJson(`${this.url}/check/domain-nameservers`, null, query, { requestHeaders: this.requestHeaders });
    }

    async searchDomainVerification(query) {
        return getJson(`${this.url}/search/domain-verification`, query, { requestHeaders: this.requestHeaders });
    }

    async getVerificationRequest(query) {
        return getJson(`${this.url}/state/domain-verification`, query, { requestHeaders: this.requestHeaders });
    }

    async createVerificationRequest(request) {
        return postJsonAcceptJson(`${this.url}/create/domain-verification`, request, null, { requestHeaders: this.requestHeaders });
    }

    async checkVerification(request) {
        return postJsonAcceptJson(`${this.url}/check/domain-verification`, request, null, { requestHeaders: this.requestHeaders });
    }

    async createDnsRecord(request) {
        return postJsonAcceptJson(`${this.url}/domain/create-dns-record`, request, null, { requestHeaders: this.requestHeaders });
    }

    async editDnsRecord(query, request) {
        return postJsonAcceptJson(`${this.url}/domain/edit-dns-record`, request, query, { requestHeaders: this.requestHeaders });
    }

    async deleteDnsRecord(query) {
        return postJsonAcceptJson(`${this.url}/domain/delete-dns-record`, null, query, { requestHeaders: this.requestHeaders });
    }

    async searchDnsRecord(query) {
        return getJson(`${this.url}/search/dns-record`, query, { requestHeaders: this.requestHeaders });
    }

    async createWebsite(request) {
        return postJsonAcceptJson(`${this.url}/create/website`, request, null, { requestHeaders: this.requestHeaders });
    }

    async editWebsite(query, request) {
        return postJsonAcceptJson(`${this.url}/edit/website`, request, query, { requestHeaders: this.requestHeaders });
    }

    async deleteWebsite(query) {
        return postJsonAcceptJson(`${this.url}/delete/website`, null, query, { requestHeaders: this.requestHeaders });
    }

    async searchWebsite(query) {
        return getJson(`${this.url}/search/website`, query, { requestHeaders: this.requestHeaders });
    }

    async createWebsiteAlias(request) {
        return postJsonAcceptJson(`${this.url}/create/website-alias`, request, null, { requestHeaders: this.requestHeaders });
    }

    async deleteWebsiteAlias(query) {
        return postJsonAcceptJson(`${this.url}/delete/website-alias`, null, query, { requestHeaders: this.requestHeaders });
    }

    async searchWebsiteAlias(query) {
        return getJson(`${this.url}/search/website-alias`, query, { requestHeaders: this.requestHeaders });
    }

    async getDispute(query) {
        return getJson(`${this.url}/state/domain-dispute`, query, { requestHeaders: this.requestHeaders });
    }

    async createDispute(request) {
        return postJsonAcceptJson(`${this.url}/create/domain-dispute`, request, null, { requestHeaders: this.requestHeaders });
    }

    async searchDomainDispute(query) {
        return getJson(`${this.url}/search/domain-dispute`, query, { requestHeaders: this.requestHeaders });
    }
}

class DynamicSharedDomain {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    /**
     * Search available dynamic shared domains that can be selected by this account
     */
    async search(query) {
        return getJson(`${this.url}/search/dynamic-shared-domain`, query, { requestHeaders: this.requestHeaders });
    }
}

class DynamicDnsRecord {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/dynamic-dns-record`, request, null, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        return postJsonAcceptJson(`${this.url}/delete/dynamic-dns-record`, null, query, { requestHeaders: this.requestHeaders });
    }

    /**
     * Search available dynamic dns records assigned to this account
     */
    async search(query) {
        return getJson(`${this.url}/search/dynamic-dns-record`, query, { requestHeaders: this.requestHeaders });
    }
}

class LinkAccountUser {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    // TODO: move this to an invitation table; link-account-user record will be created automatically when user accepts invitation
    // async create(request) {
    //     return postJsonAcceptJson(`${this.url}/create/link-account-user`, request, null, { requestHeaders: this.requestHeaders });
    // }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/link-account-user`, null, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/link-account-user`, request, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/link-account-user`, query, { requestHeaders: this.requestHeaders });
    }
}

class WebsiteTlsCertificate {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/website-tls-certificate`, request, null, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/website-tls-certificate`, query, { requestHeaders: this.requestHeaders });
    }
}

class Form {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async check(id, request) {
        return postJsonAcceptJson(`${this.url}/check/form`, request, { id }, { requestHeaders: this.requestHeaders });
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/form`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(id) {
        return getJson(`${this.url}/state/form`, { id }, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/form`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/form`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/form`, query, { requestHeaders: this.requestHeaders });
    }
}

class FormEntry {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async check(id, request) {
        return postJsonAcceptJson(`${this.url}/check/form-entry`, request, { id }, { requestHeaders: this.requestHeaders });
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/form-entry`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(id) {
        return getJson(`${this.url}/state/form-entry`, { id }, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/form-entry`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/form-entry`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/form-entry`, query, { requestHeaders: this.requestHeaders });
    }
}

class Invite {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async check(id, request) {
        return postJsonAcceptJson(`${this.url}/check/invite`, request, { id }, { requestHeaders: this.requestHeaders });
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/invite`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(id) {
        return getJson(`${this.url}/state/invite`, { id }, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/invite`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/invite`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/invite`, query, { requestHeaders: this.requestHeaders });
    }
}

class Volume {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async check(id, request) {
        return postJsonAcceptJson(`${this.url}/check/volume`, request, { id }, { requestHeaders: this.requestHeaders });
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/volume`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(id) {
        return getJson(`${this.url}/state/volume`, { id }, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/volume`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/volume`, null, query, { requestHeaders: this.requestHeaders });
    }

    async list(query) {
        return getJson(`${this.url}/search/volume`, query, { requestHeaders: this.requestHeaders });
    }
}

// TODO: move some website functions from the Domain class to this class
class Website {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/website`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(id) {
        return getJson(`${this.url}/state/website`, { id }, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/website`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/website`, null, query, { requestHeaders: this.requestHeaders });
    }

    async deployConf(request) {
        return postJsonAcceptJson(`${this.url}/rpc/deploy-website-conf`, request, null, { requestHeaders: this.requestHeaders });
    }

    async deployFromVolume(request) {
        return postJsonAcceptJson(`${this.url}/rpc/deploy-website-from-volume`, request, null, { requestHeaders: this.requestHeaders });
    }

    async list(query) {
        return getJson(`${this.url}/search/website`, query, { requestHeaders: this.requestHeaders });
    }
}

class UnicornSprings {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.requestHeaders = context.requestHeaders;
    }

    /*
    // quick status -- is there a unicorn springs customer account? is it active? is the user's profile active?
    async check(request) {
        return postJsonAcceptJson(`${this.url}/rpc/check-unicornsprings-customer`, request, null, { requestHeaders: this.requestHeaders });
    }

    // more detailed information about an existing unicorn springs account and user
    async report(request) {
        return postJsonAcceptJson(`${this.url}/rpc/report-unicornsprings-customer`, request, null, { requestHeaders: this.requestHeaders });
    }
    */

    // sign in to unicorn springs to set up or manage account; responds with redirect url
    async connect(request) {
        return postJsonAcceptJson(`${this.url}/rpc/connect-unicornsprings-customer`, request, null, { requestHeaders: this.requestHeaders });
    }
}

/**
 * This is a client for the browser and it uses session cookies to authenticate to the server.
 */
class BrowserClient {
    constructor(context = {}) {
        this.self = new CurrentAccount(context);
        this.client = new Client(context);
        this.clientToken = new ClientToken(context);
        this.domain = new Domain(context);
        this.dynamicSharedDomain = new DynamicSharedDomain(context);
        this.dynamicDnsRecord = new DynamicDnsRecord(context);
        this.form = new Form(context);
        this.formEntry = new FormEntry(context);
        this.invite = new Invite(context);
        this.linkAccountUser = new LinkAccountUser(context);
        this.volume = new Volume(context); // TODO: delete
        this.website = new Website(context); // TODO: delete
        this.websiteTlsCertificate = new WebsiteTlsCertificate(context); // TODO: delete
        this.unicornsprings = new UnicornSprings(context);
    }
}

export default BrowserClient;

export {
    CurrentAccount,
    Client,
    ClientToken,
    Domain,
    DynamicSharedDomain,
    DynamicDnsRecord,
    Form, // TODO: remove this, it's from libertybase
    FormEntry, // TODO: remove this, it's from libertybase
    Invite,
    LinkAccountUser,
    Volume, // TODO: remove this, it's from libertycloud
    Website, // TODO: remove this, it's from libertycloud
    WebsiteTlsCertificate, // TODO: remove this, it's from libertycloud
    UnicornSprings,
};
