/*!
Copyright (C) 2020 Cryptium Corporation. All rights reserved.
*/
/* eslint-disable no-console, class-methods-use-this, max-classes-per-file */

const ajax = require('axios');

async function getJson(path, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.get(path, {
        headers: {
            Accept: 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

async function postJsonAcceptJson(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request ? JSON.stringify(request) : undefined, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

class User {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/user/${context.userId}`;
        this.requestHeaders = context.requestHeaders;
    }

    async check(request) {
        return postJsonAcceptJson(`${this.url}/check`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get() {
        return getJson(`${this.url}/state`, null, { requestHeaders: this.requestHeaders });
    }

    async edit(request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit`, request, null, { requestHeaders: this.requestHeaders });
    }

    async delete() {
        return postJsonAcceptJson(`${this.url}/delete`, null, null, { requestHeaders: this.requestHeaders });
    }

    async search(request) {
        return getJson(`${this.url}/search`, request, { requestHeaders: this.requestHeaders });
    }

    async getAccountList(request) {
        return getJson(`${this.url}/search/account`, request, { requestHeaders: this.requestHeaders });
    }

    async createAccount(request) {
        return postJsonAcceptJson(`${this.url}/create/account`, request, null, { requestHeaders: this.requestHeaders });
    }

    // async create(request) {
    //     return postJsonAcceptJson(`${this.url}/user/create`, request, null, { requestHeaders: this.requestHeaders });
    // }

    // // TODO: move to unicorn springs
    // async invite(request) {
    //     return postJsonAcceptJson(`${this.url}/user/invite`, request, null, { requestHeaders: this.requestHeaders });
    // }

    // // request like { email (string), agreeToTerms (boolean) }
    // // TODO: move to unicorn springs
    // async activate(request) {
    //     return postJsonAcceptJson(`${this.url}/user/activate`, request, null, { requestHeaders: this.requestHeaders });
    // }

    // // TODO: move to unicorn springs
    // async list(request) {
    //     return getJson(`${this.url}/search/user`, request, { requestHeaders: this.requestHeaders });
    // }

    // async get(id) {
    //     return getJson(`${this.url}/user`, { id }, { requestHeaders: this.requestHeaders });
    // }

    // // TODO: move to unicorn springs
    // async edit(id, request) {
    //     // NOTE: you only need to specify the attributes that should be changed
    //     return postJsonAcceptJson(`${this.url}/user/edit`, request, { id }, { requestHeaders: this.requestHeaders });
    // }

    // // TODO: move to unicorn springs
    // async delete({ id }) {
    //     return postJsonAcceptJson(`${this.url}/user/delete`, null, { id }, { requestHeaders: this.requestHeaders });
    // }
}

class BrowserClient {
    constructor(context = {}) {
        this.self = new User(context);
    }
}

export default BrowserClient;
export {
    User,
};
