import Vue from 'vue';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faUser, faEnvelope, faPhone, faCheck, faFile,
    faCheckSquare, faPlus, faTrash, faSave, faCamera,
    faTimes, faSort, faSortDown, faCaretDown, faCaretUp, faCaretRight,
    faChevronLeft as faChevronLeftSolid,
    faChevronRight as faChevronRightSolid,
    faBriefcase, faCompress, faDesktop,
    faShieldAlt as faShieldAltSolid,
    faHeart, faUserSecret,
    faBuilding, faFingerprint, faCode, faBars, faUserCircle,
    faUserFriends as faUserFriendsSolid,
    faPencilAlt, faSearch, faEdit, faInfoCircle,
    faGlobe as faGlobeSolid,
    faCloudDownloadAlt, faUnlock,
    faEye as faEyeSolid,
    faCog as faCogSolid,
    faClock as faClockSolid,
    faWrench as faWrenchSolid,
    faChartLine as faChartLineSolid,
    faFolder as faFolderSolid,
    faFolderOpen as faFolderOpenSolid,
    faAngleRight, faAngleLeft, faAngleDoubleRight, faAngleDoubleLeft,
    faMinusSquare, faPaperclip, faArrowLeft,
    faEllipsisV as faEllipsisVSolid,
    faCloudUploadAlt as faCloudUploadAltSolid,
    faExclamationTriangle as faExclamationTriangleSolid,
    faQuestionCircle as faQuestionCircleSolid,
    faQuestion as faQuestionSolid,
    faCircle as faCircleSolid,
    faCheckCircle as faCheckCircleSolid,
    faIdBadge as faIdBadgeSolid,
    faLock as faLockSolid,
    // faStar as faStarSolid,
    // faStarHalf as faStarHalfSolid,
    faExternalLinkAlt as faExternalLinkAltSolid,
    faPlusCircle as faPlusCircleSolid,
    faMinusCircle as faMinusCircleSolid,
} from '@fortawesome/free-solid-svg-icons';
import {
    faSquare,
    faCircle as faCircleRegular,
    faCopy as faCopyRegular,
} from '@fortawesome/free-regular-svg-icons';
import {
    // faStar as faStarLight,
    faCopy as faCopyLight,
} from '@fortawesome/pro-light-svg-icons';
// import {
//     faWordpress as faWordpressBrand,
// } from '@fortawesome/free-brands-svg-icons';
import {
    faEllipsisV as faEllipsisVRegular,
    faCode as farCode,
    faExternalLinkAlt as faExternalLinkAltRegular,
    faSyncAlt as faSyncAltRegular,
} from '@fortawesome/pro-regular-svg-icons';
import {
    faQuestionSquare as faQuestionSquareSolid,
} from '@fortawesome/pro-solid-svg-icons';

function setup() {
    Vue.component('font-awesome-icon', FontAwesomeIcon);
    Vue.component('font-awesome-layers', FontAwesomeLayers);
    Vue.component('font-awesome-layers-text', FontAwesomeLayersText);
    library.add(faUser, faEnvelope, faPhone, faCheck, faFile, faCheckSquare, faSquare,
        faPlus,
        faPlusCircleSolid,
        faTrash, faSave, faCamera, faBriefcase, faCompress, faDesktop, faShieldAltSolid,
        faTimes, faSort, faSortDown, faCaretDown, faCaretUp, faCaretRight,
        faChevronLeftSolid,
        faChevronRightSolid,
        faHeart, faBuilding, faUserSecret, faFingerprint, faCode, faBars, faUserCircle,
        faUserFriendsSolid,
        faPencilAlt, faSearch, faEdit, faInfoCircle,
        faGlobeSolid,
        faCloudDownloadAlt, faUnlock,
        faEyeSolid,
        faCogSolid,
        faWrenchSolid,
        faClockSolid,
        faChartLineSolid, faFolderSolid, faFolderOpenSolid,
        faAngleRight, faAngleLeft, faAngleDoubleRight, faAngleDoubleLeft,
        faMinusSquare,
        faMinusCircleSolid,
        faPaperclip, faArrowLeft,
        faEllipsisVSolid, faCloudUploadAltSolid, faExclamationTriangleSolid,
        faQuestionCircleSolid,
        faQuestionSquareSolid,
        faQuestionSolid,
        faCircleSolid, faCircleRegular,
        faCheckCircleSolid,
        faIdBadgeSolid,
        faLockSolid,
        faExternalLinkAltSolid,
        faExternalLinkAltRegular,
        faSyncAltRegular,
        faCopyRegular,
        faCopyLight,
        // faStarLight, faStarSolid,
        // faStarHalfSolid,
        faEllipsisVRegular,
        farCode);
}

function mapFontAwesomeIcons(iconMap) {
    const output = {};
    Object.entries(iconMap).forEach(([key, value]) => {
        output[key] = {
            component: FontAwesomeIcon,
            props: {
                icon: value,
            },
        };
    });
    return output;
}

export default { mapFontAwesomeIcons, setup };
